/* add */
.add {
    margin: 0 auto;
    margin-top: 120px;
    max-width: 800px;
    /*Kalau pakai css salim wajib tambahin ini */
    /* justify-content: center !important;
    display: flex; */
  }
  
  .add .photo {
    display: flex;
    justify-content: center !important;
    flex-direction: column;
    align-items: center;
    height: 300px;
    cursor: pointer;
  }
  
  .add input, .add textarea, .add .photo {
    background-color: #EFEFEF;
    border: none;
  }
  
  .add form button {
    background-color: #EFC81A;
    max-width: 300px;
    
  }

