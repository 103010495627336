
.recipe{
   
    margin-bottom: 100px;
    
}

.recipe .nav-link {
    color: #EFC81A;
}
.recipe .nav-link:hover {
    color: #EFC81A;
}
