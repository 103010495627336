body {
  overflow-y: auto !important;
  overflow-x: hidden;
}

@font-face {
  font-family: "Airbnb";
  src: url("../../assets/fonts/airbnb.woff2");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/inter.woff2");
  font-display: swap;
}

input.signup {
  border: 1px solid #8692a6 !important;
  border-radius: 10px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="checkbox"]:checked {
  color: #696f79;
  border-color: rgba(239, 200, 26, 0.8) !important;
  box-shadow: 0 1px 1px rgba(239, 200, 26, 0.1) inset,
    0 0 6px rgba(239, 200, 26, 0.9) !important;
  outline: 0 none;
}

input[type="checkbox"]:focus {
  box-shadow: none !important;
  outline: 0 none;
}

input[type="checkbox"]:checked {
  background: #efc81a;
}

input.signup::placeholder {
  font-family: "Airbnb";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8692a6 !important;
}

input.signup {
  height: 2.825rem !important;
  background: #ffffff !important;
}

input.signup:active {
  background: #ffffff;
}

input.signup:-webkit-autofill,
input.signup:-webkit-autofill:hover,
input.signup:-webkit-autofill:focus,
input.signup:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label.inputLabelText {
  font-family: "Airbnb";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #696f79 !important;
}

.btn-warning.submit {
  background-color: #efc81a !important;
  height: 2.825rem;
}

.btn-warning.submit:hover {
  background-color: #f4d032 !important;
  border-color: rgba(239, 200, 26, 0.8) !important;
  box-shadow: 0 4px 4px rgba(239, 200, 26, 0.1) inset,
    0 0 6px rgba(239, 200, 26, 0.9) !important;
  outline: 0 none;
}

.title-text,
.login-text {
  color: #efc81a;
}

.login-text:hover {
  color: #f4d032;
}

.subtitle-text,
.have-an-account-text,
.terms-and-conditions-text {
  color: #8692a6;
}

.title-text,
.subtitle-text,
.login-text,
.have-an-account-text,
.terms-and-conditions-text {
  font-family: "Airbnb", sans-serif;
}

.title-text {
  font-weight: 700;
}

.subtitle-text {
  font-weight: 400;
}

.terms-and-conditions-text {
  margin-top: 0.1rem !important;
  font-size: 0.8rem;
}

.login-text,
.terms-and-conditions-text {
  text-decoration: none;
  font-weight: 500;
}

.column {
  width: 100%;
  height: 100%;
}

div.backdrop-signup {
  height: 150vh !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: linear-gradient(
      to bottom,
      rgba(239, 200, 26, 0.375),
      rgba(239, 200, 26, 0.6)
    ),
    url("../../assets/auth/login/backdrop.jpg");
}

@media screen and (max-width: 1500px) {
  form.signup {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
  form.signup {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  form.signup {
    width: 70%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #efc81a;
  border-radius: 15px;
  opacity: 0.2 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f4d032 !important;
  border-color: rgba(239, 200, 26, 0.8) !important;
  box-shadow: 0 4px 4px rgba(239, 200, 26, 0.1) inset,
    0 0 6px rgba(239, 200, 26, 0.9) !important;
}
