.containers{
    background-image: linear-gradient(to right, transparent 70%, #EFC81A 30%) !important;
    margin-top: -120px;
    margin-right: -1% !important;
    height: 100%;
}

.container_img img{
    margin-top: 100px;
    /* margin-left: 80px; */
    width: 75%;
    position: relative;
    right: 3%;
    top: -50px;
    display: flex;
    justify-content: end;
}

.custom{
    width: 100%  !important;
}

.title{
    font-family: "Airbnb_bold";
    margin-top: 25%;
    color: #2E266F;
    margin-left: -5% !important;
    font-weight: 700;
}

.title h1{
    margin-left: 20px;
    width: 80%;
}
.title input{
    width: 90%;
    height:50px !important;
    /* margin-left: px; */
    margin-top: -39px;
    padding-left: 40px ;
    background: white;
}

.title div.conts-icon{
    padding-top: 20px;
    margin-left: 20px;
}

.conts-icon a{
    color: #C4C4C4;
}

@media screen and (max-width: 991px){
    .containers{
        background: transparent !important;
        margin-top: 0px;
    }
}

@media screen and (max-width: 767px){
    .baner{
        display: flex !important;
        flex-direction: column;
        margin-left: -20%;
        width: 100%;
    }
    .container_img img{
        width: 180% !important;
        margin-left: -100px;
        margin-top: -30px;
    }
}
@media screen and (max-width: 576px){
    .containers{
        background: transparent !important;
        margin-top: 0px;
    }
    .containers.baner{
        display: block !important;
        flex-direction: row !important;
    }
    .baner{
        display: flex !important;
        flex-direction: column;
        margin-left: 5%;
        width: 100%;
        margin-top: -80px;
    }
    .container_img img{
        width: 90% !important;
        margin-left: 20px;
        margin-top: -30px;
    }
}