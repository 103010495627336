@font-face {
  font-family: "Airbnb";
  src: url("../../../../src/assets/detailRecipe/airbnb.woff2");
  font-display: swap;
}

.small-middle-container {
  margin: auto;
  width: 70%;
}

/* .picture {
  max-width: 600px;
  max-height: 450px;
} */

.btn-warning {
  width: 225px;
  height: 45px;
}

.commentPic {
  width: 64px;
  height: 64px;
}

div.gallerypic {
  max-width: 600px;
  text-decoration: none;
  position: relative;
  display: block;
  padding: 3px;
  margin: auto;
  /* float: left; */
}

div.gallerypic span.like {
  position: absolute;
  left: 90%;
  top: 84%;
}

div.gallerypic span.bookmark {
  position: absolute;
  left: 82%;
  top: 84%;
}
.icon {
  width: 40px;
  height: 40px;
}

@media (min-width: 1px) and (max-width: 350px) {
  div.gallerypic span.like {
    left: 84%;
    top: 73%;
  }

  div.gallerypic span.bookmark {
    left: 69%;
    top: 73%;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 351px) and (max-width: 500px) {
  div.gallerypic span.like {
    left: 87%;
    top: 80%;
  }

  div.gallerypic span.bookmark {
    left: 74%;
    top: 80%;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 501px) and (max-width: 750px) {
  div.gallerypic span.like {
    left: 88%;
    top: 82%;
  }

  div.gallerypic span.bookmark {
    left: 77%;
    top: 82%;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
}
.icon:hover {
  cursor: pointer;
}

li.list {
  list-style-type: circle;
  font-family: "Airbnb";
  font-weight: 100;
  font-size: 16px;
}
