
/* footer */
@font-face {
  font-family: "Airbnb";
  src: url(../../../assets/fonts/airbnb.woff2);
  font-display: swap;
}
.ff-airbnb {
  font-family: "Airbnb", sans-serif;
}
.footer{
    background-color: #EFC81A;
}
footer .top {
    height: 300px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
  
  footer .bottom {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  footer .bottom ul {
    display: flex;
    list-style: none;
  }
  
  footer .bottom ul li {
    margin: 0 10px;
  }
  
  footer .bottom ul li a {
    color: black;
    font-size: .8rem;
    text-decoration: none;
  }