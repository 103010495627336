body {
  overflow-x: hidden;
  overflow-y: auto
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

input.forgot-password {
  border: 1px solid #8692a6 !important;
  border-radius: 10px;
}

input[type="text"]:focus,
input[type="email"]:focus {
  color: #696f79;
  border-color: rgba(239, 200, 26, 0.8) !important;
  box-shadow: 0 1px 1px rgba(239, 200, 26, 0.1) inset,
    0 0 6px rgba(239, 200, 26, 0.9) !important;
  outline: 0 none;
}

input.forgot-password::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8692a6 !important;
}

input.forgot-password.form-control {
  width: 100%;
  height: 2.825rem !important;
  background: #ffffff !important;
}

input.forgot-password.form-control:active {
  background: #ffffff;
}

input.forgot-password:-webkit-autofill,
input.forgot-password:-webkit-autofill:hover,
input.forgot-password:-webkit-autofill:focus,
input.forgot-password:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label.inputLabelText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #696f79 !important;
}

.btn-warning.submit {
  background-color: #efc81a !important;
  height: 2.625rem;
}

.title-text {
  color: #efc81a;
}

.subtitle-text {
  color: #8692a6;
}

.title-text,
.subtitle-text {
  font-family: "Inter", sans-serif;
}

.title-text {
  font-weight: 700;
}

.subtitle-text {
  font-weight: 400;
}

.column {
  width: 100%;
  height: 100%;
}

form.forgot-password {
  justify-content: center;
  width: 50%;
}

div.backdrop-forgot-password {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: linear-gradient(
      to bottom,
      rgba(239, 200, 26, 0.375),
      rgba(239, 200, 26, 0.6)
    ),
    url("../../assets/auth/login/backdrop.jpg");
}

@media screen and (max-width: 1500px) {
  form.forgot-password {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
  form.forgot-password {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  form.forgot-password {
    width: 70%;
  }
}
