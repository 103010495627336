.title-popular-recipe{
    margin-top: -65px;
    margin-left: 20px;
}

.title-popular-recipe{
    margin-top: -23%;
    margin-left: 10%;
    font-size: 20px;
    color: white;
}

.card2 img{
    height: 100%;
    width: 100% !important;
    margin: auto;
    object-fit: fill;
    border-radius: 5px;
}
.card-title{
    margin-top: -70px;
    color: white;
}

.link{
    text-decoration: none;
    border: 2px solid transparent !important;

}

.card4{
    width: 230px;
    /* background: red; */
}
.card2{
    width: 100%;
    height: 250px;
    margin: auto;
    border-radius: 30px !important;
    background-color: transparent;
}

@media screen and (max-width: 767px){
    .card2{
        width: 400px;
        height: 340px;
        margin-left: -80px;
    }
}

